import React from "react"
import Helmet from "react-helmet"

const NotFoundPage = () => (
  <>
    <Helmet
      bodyAttributes={{
        class: "error404 full-hero short",
      }}
    />
    <section id="hero-404" className="hero">
      <div className="container">
        <div className="inner">
          <h1
            data-aos="fade-up-left"
            data-aos-delay="200"
            className="aos-init aos-animate"
          >
            404
          </h1>
          <h3
            data-aos="fade-up-left"
            data-aos-delay="400"
            className="aos-init aos-animate"
          >
            Page not found.
          </h3>
          <p
            data-aos="fade-up-left"
            data-aos-delay="600"
            className="aos-init aos-animate"
          >
            It seems like you're lost.
          </p>
          <a
            data-aos="fade-up-left"
            data-aos-delay="800"
            href="/"
            className="btn white aos-init aos-animate"
          >
            Go to homepage
          </a>
        </div>
      </div>
    </section>
  </>
)

export default NotFoundPage
